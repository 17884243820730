
.welcome-section{
    background: url('../../siteImages/welcome-shape-left.svg'), url('../../siteImages/welcome-shape-bottom-right.svg');
    background-repeat: no-repeat;
    background-position: left -70px, right 15rem;
    background-size: 600px;
    padding-top: 8rem;
    padding-bottom: 7rem;
/*    height: 50vh;
    min-height: 550px;*/
}

button.skip {
  font-size: 18px;
  width: 234px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1vw 3vw;
  border-radius: 3vw;
  background-color: #0c3a25;
  color: #fff;
  border: none;
  margin-top: 30px;
}


button.Welcome {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  height: 75px;
  padding: 0;
}

h1 {
  font-family: 'ClashGrotesk-Medium';
  font-size: 56px;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: normal;
  color: #0c3a25;
}

p {
  font-family: Public Sans;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0px;
  color: #0c3a25;
}

.welcome-section h1 {
    font-size: 40px;
    display: flex;
}

.welcome-section p{
    font-size: 16px;
}

.Welcome svg {
    margin-left: 1rem;
}


@media (max-width: 767px){
    .welcome-section {
        padding: 5rem 3rem;
        background-position: left -140px, right 47vh;
        background-size: auto;
        min-height: calc(100vh - 10rem);
    }
    button.skip {
        margin: 50px auto;
        border-radius: 30px;
    }
    .welcome-section h1{
        text-wrap: nowrap;
    }
}

@media (max-width: 500px){
    .welcome-section{
        padding: 3rem 1.5rem;
    }
    .welcome-section h1{
        font-size: 34px;
    }
    button.Welcome {
        height: 55px;
    }
}
