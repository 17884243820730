// div.home-col {
//     padding-top: 4vw;
//     border-radius: 25%;
//     padding-bottom: 3vw;
// }

// p {
// line-height: 2vw;
// }
  

.hero_container {
    background-image: url('../../siteImages/website_pattern.svg');
    background-size: 920px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: right bottom;
}

//     .inner_container{
//         display:flex; 
//         flex-direction:row; 
//         justify-content:center; 
//         align-items:center;

//         .text_container{
//             display:flex;
//             flex-direction:column;
//             justify-content:center;
//             align-items:center;
//             position:absolute;
//             right:550px;

//             .title_text{
//                 font-size: 56;
//                 width:70%;
//                 align-self: flex-end;
//                 margin-right: 57px;
//             }

//             .description_text{
//                 font-size: 20;
//                 width:50%;
//                 align-self: center;
//             }
//         }

.hero_image {
    width: calc(100% + 255px);
    border-radius: 100rem;
    margin-left: -255px;
}
//     }
// }





.info_section{
  background-color: #DEF0D8;
  .info_nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 20px;
        font-size: 25px;

        .info_nav_text {
            padding: 14px 0;
            cursor: pointer;
            margin: 0 0 0 40px;
            color: #666666;
        }

        .active{
            border-bottom: solid 2.5px;
            color: #0C3A25;
            border-color: #0C3A25;
        }
    }

    .info_canvas{
        canvas {
            width: 332px !important;
            height: 331px !important;
        }
    }

    .info_content{

        .info_content_left{

            .info_content_answer{
                margin-top: 40px;
            }
        }
    }
}

.decisions_section{
    min-height: 60vh;
    background-color: #FBF6ED;
    display: flex !important;
    justify-content: center;
    align-items: center;

    .inner_container{
        display:flex; 
        flex-direction:row; 
        justify-content:center; 
        align-items:center;

        .text_container{
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:flex-start;

            .title_text{
                font-size: 56;
            }

            .description_text{
                font-size: 20;
            }
              
        }

        
    }
}

.about-section{
    background-image: url('../../siteImages/about-bg.svg');
    background-size: 400px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: right 109%;
    .hero_image_about{
        width: 100%;
        border-radius: 200px;
    }
}

.hero_image_decision{
    width:629px;
    height:414px;
    border-radius:207px;
}

@media screen and (max-width: 769px) {
    .hero_image_decision{
        width: 100%;
        height: auto;
        border-radius:207px;
    }

    .hero_container {
        background-size: 40rem;
        background-position: -40px 114%;
        background-image: url('../../siteImages/mobile-hero-pattern-bg.svg');
    }

    .hero_image {
        width: 100%;
        height: 264px;
        border-radius: 207px;
        margin: 20px 0 0 0;
        object-fit: cover;
    }
    .choice-accordion {
        margin: 8px 20px !important;
        border-radius: 10px !important;
        background-color: #DDEFD8 !important;
    }
    .choice-accordion #panel1a-header p {
        font-size: 24px;
        font-family: ClashGrotesk-Medium;
        margin: 3px 0;
    }
    .choice-accordion #panel1a-header .MuiAccordionSummary-expandIconWrapper {
        margin-left: auto;
    }

    .info_section{
        .info_nav{
            justify-content: center;
            .info_nav_text {
                font-size: 16px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .info_canvas{
            canvas{
                width: 262px !important;
                height: 262px !important;
            }
        }
    }
    .about-section{
        background-image: none;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:after{
            content: "";
            background-size: 100%;
            background-image: url('../../siteImages/about-pattern-bg.svg');
            width: 396px;
            height: 289px;
            position: absolute;
            bottom: -8%;
            right: -25%;
            background-repeat: no-repeat;
            z-index: -1;
        }
        .hero_image_about{
            max-width: 266px;
            margin: 0 auto 25px;
            display: block;
        }
    }
}


