$folateGreen: #0c3a25;
$mintGreen:#dff0d8;
$black:#000000;
$grey:#B3B3B3;
$lightMintGreen:#F4FCF0;
$cloverGreen:#00653E;
$terracotta:#A86133;
$white:#FFFFFF;
$toast:#FAF6ED;
$light-terracotta:#E6D1BF;

@mixin ClashfontBasicStyle{
    font-family: 'ClashGrotesk-Medium';
    font-style: normal;
    font-feature-settings: 'ss01' on;
}
@mixin CircleContent{
    @include ClashfontBasicStyle;
    font-weight: 800;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
}
@mixin circles{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
@mixin weeks-days-chance{
    font-family: 'Clash Grotesk Variable';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
    padding-top: 8px;
}

.compare-your-choices {
    @include ClashfontBasicStyle;
    color: $folateGreen;
    font-size: 56px;
    line-height: 56px;
    font-weight: 500;
    text-align: center;
  }
.subTitle{
    @include ClashfontBasicStyle;
    color: $folateGreen;
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-weight: 500;
    text-align: center;
}

.four-tags-subtitle{
    font-family: 'Public Sans';
    color: $black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

}
.vector{
    border: 1px solid $mintGreen;
}
.FourTagsStyle{
    @include ClashfontBasicStyle;
    font-size: 36px;
    line-height: 41px;
    color: $black;
    font-weight: 500;
}
.weeks-number{
   
    @include CircleContent;
    color: $folateGreen;
}
.weeks{
    @include weeks-days-chance;
    color:$folateGreen
}
.Timing-circles{
    @include circles;
    background-color: $mintGreen;
}
.Labor-circles{
    @include circles;
    background-color:$folateGreen;
}
.days-number{
    @include CircleContent;
    color: $mintGreen;
}
.days{
    @include weeks-days-chance;
    color: $mintGreen;
}
.experience-7{
    @include ClashfontBasicStyle;
    color: $black;
    font-weight: 500;
}
.pain-percent-circlesContent{
    @include ClashfontBasicStyle;
    color: $black;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
}
.chance{
    font-family: 'Clash Grotesk Variable';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
/* or 150% */
    letter-spacing: 0.25px;
    color: $black;
}
@mixin commonContent{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    
}
.no-difference{
    @include commonContent;
    color: $black;
    
}
.same-rectangle{
    background: $lightMintGreen;
    border-radius: 12px;
    padding-top: 1rem;
    padding-bottom:1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.same-for-all-opts{
    @include commonContent;
    color: $black;
}
.same-breastfeed{
    @include commonContent;
    color: $cloverGreen;
}
.potential-risks-title{
    @include ClashfontBasicStyle;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    color: $terracotta;
}
.potential-risks-6{
    @include ClashfontBasicStyle;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.5px;
    padding-left: 4px;
    padding-top: 20px;
    color: $terracotta;
}
.view-risks-accordion{
    background-color: $terracotta;
    

}
.view-risks-details{
    background-color: $toast;
}
.view-risks-title{
    color: $white;
    font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */


}
.expandIcon{
    color: $white;
   
    
}
.learn-choices{
    @include ClashfontBasicStyle;
    color: $folateGreen;
    font-weight: 500;
    font-size: 36px;
    line-height: 41px;
    text-align: center;

}
.learn-choices-subTitle{
    @include ClashfontBasicStyle;
    color: $folateGreen;
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
}

.sources-accordion{
    width: 874px
}
.sources{
    @include ClashfontBasicStyle;
    font-weight: 450;
    font-size: 24px;
    line-height: 31px;
    color: $folateGreen;
}
.need-help{
    background-color: $toast;
    width: 100%;
    height: 533px;
}
#william-image{
    margin-top: 50px;
    border-radius: 1115px;
    width: 100%;
    height: 100%;
}

.need-help-choosing-text{
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    margin-top: 8px;
}
.find-out-button{
    background-color: $folateGreen;
    width: 120px;
    height: 40px;
    border-radius: 25px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    font-feature-settings: 'ss01' on;
    color: $white;
}
.experience-body-content{
    /* Body/Medium */
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    align-content: center;
    justify-content: center;

}
.cesarean-birth-8{
    font-family: 'ClashGrotesk-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-align: left;
    color: $black;
}
.vector-risks{
    border: 1px solid $terracotta;
}
.potential-risks-small-content{
    /* Body/Medium */
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    text-align: center;
    color: $black;
}
.potential-risks-small-content2{
    /* Body/Medium */
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
    display: inline-block;
}
.for-all-rectangle{
    background:$light-terracotta;
    border-radius: 0px 0px 12px 12px;
}
.for-all-small-number{
    font-family: 'ClashGrotesk-Medium';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.5px;
    color: $black;
}