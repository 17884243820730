$terracotta:#A86133;

.root {
  background-image: url('../../siteImages/detail-bg.jpg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 600px;
  padding-top: 1rem; 
  min-height: 10vh;
}

.potential-risks{
  background:#E6D1BF;
  border-radius: 0px 0px 12px 12px;
}

.foot-big-nunmber{
  font-family: 'ClashGrotesk-Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-align: left;
  color: #000000;
}


.potential-risks-content{
  /* Body/Medium */

  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  color: #000000;
}

.potential-risks-foot-content {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
}

.potential-risks-foot-content svg {
    width: 6px;
    float: left;
    height: 21px;
    margin-bottom: 17px;
}

.foot-small-number{
  font-family: 'ClashGrotesk-Medium';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  color: #000000;
  font-weight: bolder;
  margin-left: 3px;
}

.same-chance-small-digit{
  font-family: 'ClashGrotesk-Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-align: left;
  font-weight: bold;
}

.ThreeTagsStyle {
    font-family: 'ClashGrotesk-Medium';
    font-style: normal;
    font-feature-settings: "ss01" on;
    font-size: 1.5rem;
    line-height: normal;
    color: #000000;
    font-weight: 500;
    display: inline-block;
}

.bodyText {
  margin-left: 30px;
  margin-top: 20px;
  color: #4D4D4D;
  margin-bottom: 50px;
  line-height: 24px;
}

.bodyText1 {
  font-family: 'Public Sans';
  text-align: center;
  color: #4D4D4D;
  font-size: 16px;
  line-height: 24px;
}

.linkText {
  margin-left: 30px;
  color: #4D4D4D;
  margin-bottom: 50px;
  line-height: 24px;
}

.avatar {
  width: 160px;
  height: 160px;
  font-size: 3rem;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space {
  margin-bottom: 2rem; 
  color: #4D4D4D;
  font-size: 16px;
  line-height: 24px;
}


.rightTitle {
  font-size: 32px;
  color: #0C3A25;
}

#first-image {
  margin-top: 50px;
  border-radius: 1115px;
  width: 500px;
  height: 350px;
}

.btmTip {
  margin-top: 20px;
  color: #0C3A25;
  font-weight: bold;
}

.post {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  background-color: #DFF0D8;
  border-radius: 10px;
}

.post1 {
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: #F4FCF0;
  border-radius: 10px;
}

.mainText {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.whitePost {
  padding: 24px;
  margin-bottom: 32px;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.title {
  font-family: 'ClashGrotesk-Medium';
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0px;
  color: #0C3A25;
  font-weight: bold;
}

.secondTitle {
  font-family: 'ClashGrotesk-Medium';
  font-size: 36px;
  line-height: initial;
  letter-spacing: 0px;
  color: #0C3A25;
  font-weight: bold;
  display: flex;
}

.secondTitle.title2 {
  color: #A86133;
}

.subTitle {
  font-family: 'ClashGrotesk-Medium';
  font-size: 26px;
  line-height: 56px;
  letter-spacing: 0px;
  color: #0C3A25;
  font-weight: bold;
}

button.container {
  height: '25px',
}

.accordion-details:before {
  display:none;
}


.accordion-details #panel1a-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    min-height: auto !important;
    margin-bottom: 1rem;
    padding: 0px;
}

.accordion-details #panel1a-header .MuiAccordionSummary-content {
    margin: 0;
}

.link-btn {
    align-items: center;
    border: 1px solid #0c3a25;
    border-radius: 30px;
    color: #0c3a25;
    display: flex;
    font-family: ClashGrotesk-Medium !important;
    padding: 5px 12px;
    text-decoration: none !important;
}

.link-btn:hover{
    background-color: #0c3a25;
    color: #ffffff;
    border-color: #0c3a25;
}

img#first-image-shape {
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}

img#first-image-round {
    margin-top: 50px;
    border-radius: 200px;
    /*width: 85%;
    object-fit: cover;
    object-position: top;*/
}

.source-content p {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    margin-bottom: 20px;
}

.source-content p a {
    color: #00653E;
    font-weight: bold;
}

.source-content p br{
  display:none;
}

.bottomTitle span {
    font-size: 14px;
    margin-left: 7px;
}

.details-accordion div#panel1a-header > div {
    align-items: center;
    flex-grow: inherit;
}


.details-content{
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    font-family: Public Sans;
}

.details-content p {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
}

.details-content ul li {
    font-family: Public Sans;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
}

.details-content ul {
    padding-left: 30px;
}
/*.main-image {
    height: 351px;
}*/

.author-profile-avatar{
    width: 70px;
    height: 70px;
}

.author-profile-paragraph{
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
}

.author-profile-name {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: rgb(77, 77, 77);
}

.explore-btn {
    margin-right: 10px;
}

.image-content-block-risk .bottomTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1rem;
}

.image-content-block-risk {
    position: relative;
    margin-top: auto;
}
.MuiPopover-root .MuiGrid-container h4.MuiTypography-root {
    flex: 0 auto;
}

.link-green>a {
    color: #00653E;
    font-weight: bold;
}
.ThreeTagsStyle.font-size-16 {
    font-size: 16px;
}

@media screen and (max-width: 769px) {
    .mainText{
      padding: 0px;
    }
    img#first-image-round {
        width: 100%;
        margin-top: 30px;
        border-radius: 141.88px;
        height: 257px;
    }
    .secondTitle{
      font-size: 24px;
    }
    .bodyText {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .bottomTitle {
        font-size: 1.5rem;
    }
    .maintitle2{
      font-size: 32px;
    }
    .m-font-size-24{
      font-size: 1.5rem;
    }
    .link-btn {
        font-size: 18px;
    }
    .rightTitle {
        font-size: 24px;
    }

    .potential-risks-foot-content {
        position: relative;
        padding-left: 13px;
    }
    .potential-risks-foot-content:before {
        content: "";
        background: #000;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        border-radius: 100%;
        top: 7px;
    }
    .potential-risks-foot-content svg {
        display: none;
    }

    .author-profile-avatar {
        width: 53px;
        height: 53px;
    }
    .author-profile-paragraph {
        font-size: 14px;
        margin-top: 1px;
    }
    .explore-btn {
        margin-right: 0px;
        margin-bottom: 15px;
    }
    .details-content ul {
        padding-left: 26px;
    }
    .mobile-font-14{
        font-size: 14px;
    }
    .explore-btn button {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        border-width: 1px;
        font-family: 'ClashGrotesk-Medium';
    }
    .explore-btns-grid {
        justify-content: space-between;
    }
}

